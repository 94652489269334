import React from 'react'
import { Router, RouteComponentProps, Link } from '@reach/router'
import { login, logout, isAuthenticated, getProfile } from '../utils/auth'

import Page from '../components/Page'
import Layout from '../layouts/layout'

interface User {
  name?: string
}

// const Home = (user: User) => {
//   return <p>Hi, {user.name ? user.name : 'friend'}!</p>
// }

// // const Settings = () => <p>Settings</p>
// // const Billing = () => <p>Billing</p>

// const Home = (props: RouteComponentProps) => <p>Hi!</p>
// // let Home = (user: User, props: RouteComponentProps) => <p>Hi, {user.name ? user.name : 'friend'}!</p>
// const Settings = (props: RouteComponentProps) => <div>Settings</div>
// const Billing = (props: RouteComponentProps) => <div>Billing</div>

let Home = (props: RouteComponentProps) => <div>Home</div>
let Dash = (props: RouteComponentProps) => <div>Dash</div>
interface profileProps extends RouteComponentProps {
  user?: User
}
let Profile = (props: profileProps) => <div>Hello {props.user ? (props.user.name ? props.user.name : props.user) : 'friend2'}</div>

const Admin = () => {
  if (!isAuthenticated()) {
    login()
    return <p>Redirecting to login...</p>
  }

  const user: User = getProfile()

  return (
    <Layout>
      <Page>
        <>
          <nav>
            <Link to="/admin/">Home</Link> <Link to="/admin/dashboard/">dashboard</Link> <Link to="/admin/profile/">profile</Link>{' '}
            <a
              href="#logout"
              onClick={e => {
                logout()
                e.preventDefault()
              }}
            >
              Log Out
            </a>
          </nav>
          <Router>
            <Home path="/admin/" />
            <Dash path="/admin/dashboard/" />
            <Profile path="/admin/profile/" user={user} />
          </Router>
        </>
      </Page>
    </Layout>
  )
}

// <Router>
//             <Home path="/admin/" /> {/* user={user} */}
//             <Settings path="/admin/settings" />
//             <Billing path="/admin/billing" />
//           </Router>

export default Admin

const RoutedPage = (props: { pageComponent: JSX.Element } & RouteComponentProps) => props.pageComponent
